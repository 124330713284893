/* src/Chat.css */
.chat-container {
    display: flex;
    flex-direction: column;
    height: 400px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .chat-messages {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    background-color: #f9f9f9;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .chat-message {
    margin: 5px 0;
    padding: 8px;
    border-radius: 5px;
    max-width: 70%;
    word-wrap: break-word; /* Allow long words to break */
    overflow-wrap: break-word; /* Modern equivalent */
  }
  
  .chat-message.User {
    background-color: #d1e7dd;
    align-self: flex-end;
  }

  .chat-message.Other {
    background-color: #f1f1f1; /* Light gray background for other messages */
    align-self: flex-start; /* Align other messages to the left */
  }
  
  .chat-messages {
    display: flex;
    flex-direction: column;
  }
  
  .chat-input {
    display: flex;
    padding: 10px;
    background-color: #fff;
  }
  
  .chat-input input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .chat-input button {
    margin-left: 10px;
    padding: 10px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .chat-input button:hover {
    background-color: #0056b3;
  }

  .no-chats{
    background-color: #d1e7dd;
    align-self: center;
    border-radius: 4px;
    padding: 3px;
    font-size: 12px;
    font-weight: 600;
  }