body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Loader */
.mesh-loader {
  position: fixed;
  background-color: rgb(0, 0, 0, 0.2);
  height: 100vh;
  width: 100vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999;
}
.mesh-loader .circle {
  width: 25px;
  height: 25px;
  position: fixed;
  background: #2962ff;
  border-radius: 50%;
  margin: -12.5px;
  -webkit-animation: mesh 3s ease-in-out infinite;
  animation: mesh 3s ease-in-out infinite -1.5s;
}

.mesh-loader > div .circle:last-child {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.mesh-loader > div {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 99999999999;
}

.mesh-loader > div:last-child {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
/* Loader */

@-webkit-keyframes mesh {
  0% {
    -webkit-transform-origin: 50% -100%;
    transform-origin: 50% -100%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  50% {
    -webkit-transform-origin: 50% -100%;
    transform-origin: 50% -100%;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  50.00001% {
    -webkit-transform-origin: 50% 200%;
    transform-origin: 50% 200%;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform-origin: 50% 200%;
    transform-origin: 50% 200%;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes mesh {
  0% {
    -webkit-transform-origin: 50% -100%;
    transform-origin: 50% -100%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  50% {
    -webkit-transform-origin: 50% -100%;
    transform-origin: 50% -100%;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  50.00001% {
    -webkit-transform-origin: 50% 200%;
    transform-origin: 50% 200%;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform-origin: 50% 200%;
    transform-origin: 50% 200%;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.autocomplete {
  width: 100%;
  position: absolute;
  z-index: 5;
  box-shadow: 0px 5px 24px -15px black;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 100%;
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
  background-color: white;
}
.autocomplete .autocomplete_inner {
  padding: 7px 10px 7px 15px;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}
.autocomplete .autocomplete_inner.active,
.autocomplete .autocomplete_inner:hover {
  cursor: pointer;
  background-color: #ebebeb;
  color: #000;
}
.custom-gallery {
  display: flex;
  flex-wrap: wrap;
  margin: 0 3px;
  line-height: 0;
}

.custom-gallery::after {
  content: '';
  flex-grow: 1000;
}

.custom-gallery .imageItem-Vertical {
  flex-grow: 66.667;
  flex-basis: 192px;
  margin: 3px;
  position: relative;
}

.custom-gallery .imageItem-Horizontal {
  flex-grow: 150;
  flex-basis: 432px;
  margin: 3px;
  position: relative;
}

.custom-gallery .imageItem-square {
  flex-grow: 100.5;
  flex-basis: 287.5px;
  margin: 3px;
  position: relative;
}
.custom-gallery .imageItem-HorizontalContributor {
  flex-grow: 150;
  flex-basis: 300px;
  margin: 3px;
  position: relative;
}
.custom-gallery .imageItem-VerticalContributor {
  flex-grow: 66.667;
  flex-basis: 133px;
  margin: 3px;
  position: relative;
}

.custom-gallery img {
  cursor: pointer;
  transition: all 0.5s;
  width: 100%;
}

.image-menu-main {
  display: none;
}
.pie-menu-main-div:hover .image-menu-main {
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: rgb(0, 0, 0, 0.5);
  animation: fade-in 1s;
  z-index: 1;
}

.textfield-research-form .MuiOutlinedInput-notchedOutline {
  border: 2px solid #d2d2d2 !important;
  border-radius: 14px !important;
}
.textfield-research-form .Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 2px solid #9f9f9f !important;
  border-radius: 14px !important;
}
.textfield-research-form .MuiInputLabel-outlined {
  color: rgb(52, 52, 52) !important;
}
.textfield-research-form .MuiOutlinedInput-input {
  color: #9f9f9f !important;
  padding: 20px 5px !important;
}
.textfield-research-form .MuiOutlinedInput-input {
  color: rgb(52, 52, 52) !important;
}
.textfield-research-form {
  font-weight: 600 !important;
}
/* .remarksTextArea .MuiOutlinedInput-input{
  font-weight: 600 !important;
} */
.remarksTextArea .MuiOutlinedInput-input.Mui-disabled{
  -webkit-text-fill-color:rgb(52, 52, 52);

}
