.pagination-container {
  display: flex;
  list-style-type: none;
  margin-top: 10px;
  /* justify-content: flex-end; */
  overflow: hidden;
  overflow-x: auto;
}
.pagination-container .pagination-item {
  padding: 12px;
  height: 30px;
  text-align: center;
  margin: auto 0px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  /* border: 0.5px solid #edefef; */
  line-height: 15px;
  font-size: 14px;
  /* min-width: 36px; */
  border-radius: 4px;
  margin: 0 14px;
}
.pagination-container .pagination-item:first-child {
  border-radius: 4px;
  margin-left: 0;
  margin-right: 0.5rem;
  background-color: #464255;
  color: #fff;
  padding: 5px 10px;
  /* width: 80px; */
}
.pagination-container .pagination-item:first-child:hover {
  border-radius: 4px;
  margin-right: 0.5rem;
  /* background-color: #D9D9D9;
    color: #464255; */
  background-color: #5d5d5d;
  color: #fff;
}
.pagination-container .pagination-item:last-child {
  border-radius: 4px;
  margin-left: 0.5rem;
  margin-right: 0;
  /* background-color: ''; */
  background-color: #464255;
  color: #fff;
  width: 55px;
}
.pagination-container .pagination-item:last-child:hover {
  border-radius: 4px;
  margin-left: 0.5rem;
  /* background-color: ''; */
  background-color: #5d5d5d;
  color: #fff;
}
.pagination-container .pagination-item.dots:hover {
  background-color: transparent;
  cursor: default;
}
.pagination-container .pagination-item:hover {
  background-color: #58cdff;
  cursor: pointer;
}
.pagination-container .pagination-item.no-hover-effect:hover {
  background-color: transparent;
  cursor: unset;
}
.pagination-container .pagination-item.selected {
  background-color: #58cdff;
  color: #fff;
}
.pagination-container .pagination-item.disabled {
  pointer-events: none;
  background-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.87);
}
.pagination-container .pagination-item.disabled:hover {
  background-color: transparent;
  cursor: default;
}
.select-input-type {
  margin-top: 10px;
  margin-right: 10px;
  background: #464255;
  cursor: pointer;
  color: white;
  padding: 5px;
  border-radius: 4px;
  border: none;
}
