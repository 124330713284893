.parentButtonCross{
    position: relative;
    padding: 1px;
    font-size: 12px;
    
}
.childButtonCross{
    position: absolute;
    top: -30%;
    right: -10%;
    color: brown;
    border-radius: 20px;
    

}