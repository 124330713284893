.quill *{
    font-family: unset;
}
.ql-editor{
    min-height: 300px;
    /* line-height: 1.42; */
}
.ql-snow .ql-picker-options{
    background-color: #d9d9d9 !important;
}
/* .ql-editor div, .ql-editor li {
    line-height: 200%;
  } */
