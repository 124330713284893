.react-tel-input .flag-dropdown {
    background-color: #fff0;
    border: none;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
    background-color: #fff0;
}

.react-tel-input .form-control {
    width: 100%;
}

.react-tel-input .selected-flag {
    width: 43px;
    padding: 0 0 0 13px;
}

.react-tel-input .flag-dropdown.open {
    background: #fff0;
}

.react-tel-input .flag-dropdown.open .selected-flag {
    background: #fff0;
}

.main-login-form.react-tel-input .form-control {
    border: 2px solid #d2d2d2 !important;
    border-radius: 14px !important;
    color: rgb(0, 0, 0) !important;
    /* font-weight: 600 !important; */
    font-family: "Poppins" !important;
    /* font-style: normal !important; */
    font-size: 16px !important;
}

.main-login-form.react-tel-input .form-control:disabled {
    color: rgba(0, 0, 0, 0.38) !important;
}

.floatingLablePhone {
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0 5px;
    position: relative;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 24px);
    position: absolute;
    left: 0;
    top: 3;
    background-color: #fff;
    -webkit-transform: translate(14px, -9px) scale(0.75);
    -moz-transform: translate(14px, -9px) scale(0.75);
    -ms-transform: translate(14px, -9px) scale(0.75);
    transform: translate(14px, -9px) scale(0.75);
    -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    z-index: 1;
    pointer-events: auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* color: rgb(52, 52, 52) !important; */
    font-family: "Poppins" !important;
    font-style: normal !important;
}

.custom .react-tel-input .form-control {
    background: #ffffff;
    border: 2px solid #d2d2d2;
    border-radius: 14px;
    padding: 24px 0 24px 30px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    width: 43%;
    font-size: 18px;
    line-height: 25px;
    color: #212121;
}

.custom .react-tel-input .form-control:disabled {
    color: rgba(0, 0, 0, 0.38);
}

@media only screen and (min-width: 1025px) and (max-width: 1350px) {
    .custom .react-tel-input .form-control {
        padding: 12px 0 12px 50px;
        font-size: 16px;
        line-height: 20px;
    }
}

@media only screen and (min-width: 750px) and (max-width: 1024px) {
    .custom .react-tel-input .form-control {
        padding: 12px 0 12px 50px;
        font-size: 16px;
        line-height: 20px;
        width: 83%;
    }
}

@media only screen and (min-width: 501px) and (max-width: 749px) {
    .custom .react-tel-input .form-control {
        padding: 12px 0 12px 50px;
        font-size: 16px;
        line-height: 20px;
        width: 83%;
    }
}

@media only screen and (min-width: 280px) and (max-width: 500px) {
    .custom .react-tel-input .form-control {
        padding: 12px 0 12px 50px;
        font-size: 16px;
        line-height: 20px;
        width: 93%;
    }
}