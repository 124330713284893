.home-image-list-image {
    width: 100%;
}

.home-image-list-div {
    position: relative;
}

.home-image-list-div:hover .home-image-list-text {
    display: block;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    font-weight: 700;
    color: white;
    animation: fade-in 1s;
    letter-spacing: 1.7px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    margin-top: 0px;
    /* margin-bottom: 3px; */
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: calc(100% - 8px);
}

.home-image-list-text {
    display: none;
}

.image-liked-section {
    margin-top: 30px;
}
.icon-for-edit{
    display:none;
    position: absolute;
}
.icon-for-edit:hover{
    position: absolute;
    top: 5px;
    right: 5px; 
}

.home-image-list-div:hover .icon-for-edit {
    display: block;
    position: absolute;
    color: white;
    animation: fade-in 1s;
    right: 5px;
    top: 5px;
    /* margin-top: 0px; */
    display: flex;
    justify-content: space-around;
    
}
.sidebar_toggle {
    position: fixed;
    top: 20px;
    left: 206px;
    background: #fff;
    padding: 5px 5px;
    border-radius: 50%;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    z-index: 2000;
    border: none;
  }
  
  .sidebar_toggle_close {
    position: fixed;
    top: 20px;
    left: 100px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    background: transparent;
    padding: 6px 6px 4px 6px;
    border-radius: 50%;
    z-index: 2000;
    border: none;
  }
  
  .sidebar_toggle svg,
  .sidebar_toggle_close svg {
    width: 20px;
    height: 20px;
  }
